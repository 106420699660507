import * as React from "react"
import Layout from "../../components/layout"
import * as style from "./csr.module.css"
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import PdfLogo from '../../images/pdflogo.png';
import { Seo } from "../../components/seo";
import behaviour from "../../images/Behavior_Momentum_Foundation.jpg";
import friend from "../../images/Friend_In_Need.jpg";
import safe from "../../images/Safe_Water.jpg";

export default function CSRPage() {
    const [showBehaviour, setshowBehaviour] = React.useState(false);
    const [showFriend, setshowFriend] = React.useState(false);
    const [showSafe, setshowSafe] = React.useState(false);
    const [leftPosition, setLeftPosition] = React.useState('0');
    const showBehave = () => setshowBehaviour(true);
    const closeBehave = () => setshowBehaviour(false);
    const showFriendDialog = () => setshowFriend(true);
    const hideFriendDialog = () => setshowFriend(false);
    const showSafeDialog = () => setshowSafe(true);
    const hideSafeDialog = () => setshowSafe(false);
    const download = (link) => {
        window.open(link, '_blank');
    };

    const onClickLeft = () => {
        if (leftPosition == '-66%') {
            return;
        } else {
            setLeftPosition('-66%');
        }
    }

    const onClickRight = () => {
        if (leftPosition == '0') {
            return;
        } else {
            setLeftPosition('0');
        }
    }

    return (
        <Layout>
            <div className={style.container}>
                <div
                    style={{
                        backgroundColor: "rgba(0,0,0,0.5)",
                        padding: "0 10px",
                        borderBottom: "2px solid #ff5f13",
                        color: 'white'
                    }}
                >
                    <h1>Corporate Social Responsibility</h1>
                </div>
            </div>
            <div className="container-fluid">
                <div className={style.service_heading}>
                    <h1 className={style.back_text}>CSR</h1>
                    <h1
                        style={{
                            color: "#0e132a",
                            fontWeight: "700",
                            fontSize: "50px",
                        }}
                    >
                        Corporate Social Responsibility
                    </h1>
                </div>
                <p>
                    For Powertec, business success includes not just profitability and growth rate, but also our impact on the community at large. Powertec aims to integrate social, environmental and ethical responsibilities into the governance of the business as we seek to supplement the role of the government in enhancing social welfare.
                </p>
                <p>
                    With this in mind, Powertec allocates a budget of no less than 2% of the average net profits of the company made during the three immediately preceding financial years towards Corporate Social Responsibility (CSR). The formulation of the company’s CSR policy is undertaken by a Corporate Social Responsibility Board comprising of Dr. S Srikumar (our Managing Director) and Ms. Sujatha Srikumar (Director), who recommend activities undertaken by the company as part of its CSR as well as the expenditure to be incurred in supporting these activities.
                </p>
                <p>
                    A formal breakdown of the company’s CSR policy as well as projects supported by us can be found below.
                </p>
                <div className="row mb-3">
                    <div className="col-md-4 mb-2">
                        <Card>
                            <Card.Img height={302} variant="top" src={behaviour} />
                            <Card.Body>
                                <Card.Title>Behavior Momentum Foundation</Card.Title>
                                <Card.Text>
                                    Autism is a severe learning disability and a neuro-biological disorder estimated
                                    to affect 1 in 88 children (Centre for Disease Control, United States).
                                </Card.Text>
                                <Button variant="danger" onClick={showBehave}>Read More</Button>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-4 mb-2">
                        <Card>
                            <Card.Img height={302} variant="top" src={friend} />
                            <Card.Body>
                                <Card.Title>Friend In Need India</Card.Title>
                                <Card.Text>
                                    Friend In Need Trust India (FIN) is focused on highlighting
                                    the importance of universal safe sanitation standards in all parts of the world.
                                </Card.Text>
                                <Button variant="danger" onClick={showFriendDialog}>Read More</Button>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-4">
                        <Card>
                            <Card.Img height={302} variant="top" src={safe} />
                            <Card.Body>
                                <Card.Title>Safe Water Network</Card.Title>
                                <Card.Text>
                                    Safe Water Network has been working alongside communities in Ghana
                                    and India since 2009 to establish decentralized...
                                </Card.Text>
                                <Button variant="danger" onClick={showSafeDialog}>Read More</Button>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <h2>CSR Project Report</h2>
                <div className={style.carousel_container + ' mb-3'}>
                    <div className={style.slide_left + ' ' + style.navigation} onClick={onClickRight}>{'<'}</div>
                    <div className={style.slide_right + ' ' + style.navigation} onClick={onClickLeft}>{'>'}</div>
                    <div className={style.carousel_items + ' row'} style={{ left: `${leftPosition}` }}>
                        <div className="col-md-4">
                            <Card>
                                <Card.Body>
                                    <Card.Title>Safe Water Network</Card.Title>
                                    <Card.Text className={style.card_image}>
                                        <img src={PdfLogo} alt='pdf logo' />
                                    </Card.Text>
                                    <Button variant="danger" onClick={() => download('https://drive.google.com/file/d/13IALy8AmFOLSxuXnQKURi8hLYIAMVMT6/view?usp=sharing')}>Read More</Button>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card>
                                <Card.Body>
                                    <Card.Title>PEPL CSR Policy</Card.Title>
                                    <Card.Text className={style.card_image}>
                                        <img src={PdfLogo} alt='pdf logo' />
                                    </Card.Text>
                                    <Button variant="danger" onClick={() => download('https://drive.google.com/file/d/123AhnYqAn4CYUc2K6PrU5J8uAWXgp3Yl/view?usp=sharing')}>Read More</Button>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card>
                                <Card.Body>
                                    <Card.Title>Friend In Need Foundation</Card.Title>
                                    <Card.Text className={style.card_image}>
                                        <img src={PdfLogo} alt='pdf logo' />
                                    </Card.Text>
                                    <Button variant="danger" onClick={() => download('https://drive.google.com/file/d/1uyEPdR4Qi_35ZMA1VjclXeqQesVLi9_0/view?usp=sharing')}>Read More</Button>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card>
                                <Card.Body>
                                    <Card.Title>Behaviour Momentum India Foundation</Card.Title>
                                    <Card.Text className={style.card_image}>
                                        <img src={PdfLogo} alt='pdf logo' />
                                    </Card.Text>
                                    <Button variant="danger" onClick={() => download('https://drive.google.com/file/d/1X4gliD5iUsfOWVV-Rzuu5XTuxuE_APxe/view?usp=sharing')}>Read More</Button>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
            {showBehaviour && <Modal size="lg" show={showBehaviour} onHide={closeBehave} centered scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Behavior Momentum Foundation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Autism is a severe learning disability and a neuro-biological disorder estimated
                        to affect 1 in 88 children (Centre for Disease Control, United States).
                        Children and adults affected by Autism have profound difficulties in communication and socialization.
                        Most of them are not able to attend school or lead a normal life in other ways.
                    </p>
                    <p>
                        With professional help, behavioral interventions based on the science of
                        Applied Behavior Analysis (ABA) and 1:1
                        training persons with autism can learn and make substantial progress in all spheres of life.
                    </p>
                    <p>
                        BMI Foundation, headquartered in Bangalore, was established in 2014
                        by professionals with extensive experience
                        in educating and training persons with autism with the following mission:
                    </p>
                    <p>
                        “Provide highest quality services and facilities for persons with autism,
                        without discrimination, to enhance their potential towards a life of inclusion and mainstreaming and provide a
                        safe and enriched environment for a life of dignity with highest standards.”
                    </p>
                    <p>
                        Powertec has provided financial support to Behavior Momentum Foundation towards
                        the Project Paripri, Assisted living for Autistic Adults
                        (Primary Health Center, Vocational Training and Farming). Project Paripri was initiated to
                        address the question that daunts most parents, “What after us?”. Paripri is built to
                        international standards and aim to provide adults with autism a congenial environment and
                        life with dignity when parents are no longer able to take care of them or when parents pass away.
                        The vision is to have adequate opportunities to engage in vocational activities, have health
                        monitored closely, engage in exercise and leisure activities, continue to learn new skills and
                        enjoy a good quality life with dignity. Powertec has contributed Rs. 7 lakh towards the completion of
                        Phase 1 (structure and shed fabrication) and a further
                        Rs. 3 lakh for Phase 3 (windows, doors and shutters) of the vocational training unit at Paripri.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeBehave}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            {showFriend && <Modal size="lg" scrollable={true} show={showFriend} onHide={hideFriendDialog} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Friend In Need India</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Friend In Need Trust India (FIN) is focused on highlighting the importance of
                        universal safe sanitation standards in all parts of the world. Though people
                        often think of the global challenge of lack of access to sanitation or a
                        functioning toilet as having single and definite response, there is nothing so
                        simple about the complex reality that we live in: solutions are often time and geography-dependent,
                        as has been made clear by FIN’s explorations in Kameswaram, a coastal village in Tamil Nadu, India.
                    </p>
                    <p>
                        The campaign for universal sanitation has led to the diffusion of millions
                        of pit latrines all across the developing world as the means to achieving a base
                        level of hygiene. But, in coastal villages, the latrine-style single pit and double
                        pit latrines have proven to be inefficient in protecting the population, if not
                        counterproductive. Time and research have shown that these latrines can contribute
                        to the contamination of groundwater sources, leading to a higher incidence of
                        diarrhoeal diseases. This is because coastal villages are high water table areas,
                        where faecal matter from pit latrines can leach into the ground water sources.
                        The solution seemingly lies in another design innovation; a dry flush system which
                        avoids the collateral of spread
                        to groundwater sources, while providing organic compost made of human waste for agricultural use.
                    </p>
                    <p>
                        Thus, a central mission of FIN is to promote the usage of these dry flush toilets with urine diversion, commonly known as ecosan toilets (i.e. hygienic for the ecology and ultimately for humans) in coastal villages. While the construction of such eco-sanitary inventions is not new to Friend In Need (which has built over 300+ of these constructions), having it be accepted in coastal areas still remains a challenge. The ecosan toilets are not popular given the cultural context which prefers anal cleansing with water rather than with toilet paper as in much of South Asia, the Middle East, and much of Muslim Africa. Ecosan toilets also require more efforts for usage and maintenance. Therefore, many more ecosan toilets need to be adopted and its utility has to be demonstrated to the community in order for it to become a popular mainstream model.
                    </p>
                    <p>In particular, there is a dire need to repair existing toilets, build new ones and ensure efficient adoption of toilets through information, education, and communication (IEC) interventions in Kameswaram village, located in the Nagapattinam District of Tamil Nadu State. As Kameswaram is a coastal village, for durability, the toilet has to be climate resilient, being able to withstand salt in the air that leaches walls and cyclone resistant (which occur almost annually). Furthermore, the standard single pit and/or double pit latrine are unsuitable for high table water areas like coastal regions, because the soil around the pit in such regions cannot absorb the water content in fecal sludge effectively throughout the year. This means that the toilet becomes unusable during the rainy season when it is needed most and over time the ground water becomes contaminated with excreta related pathogens (e.g., E. coli; salmonella). In response, Friend in Need India Trust (FIN) works to popularize the dry-flush (ash), urine diverting toilet popularly referred to as the ecosan toilet as it is safe for the environment. This is a circular integrated solution system for India’s coastal villages. Powertec made a donation of Rs. 3 lakhs to FIN for the construction of four ecosan toilets and bathrooms in Kameswaram.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={hideFriendDialog}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            {showSafe && <Modal size="lg" scrollable={true} show={showSafe} onHide={hideSafeDialog} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Safe Water Network</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Safe Water Network has been working alongside communities in Ghana
                        and India since 2009 to establish decentralized and locally owned community
                        water purification systems that provide affordable, reliable and safe off-grid
                        drinking water. Over the past decade, Safe Water Network India has
                        established 350 safe water stations in Maharashtra, Telangana, and Uttar
                        Pradesh, providing safe water access to over 1.3 million beneficiaries and
                        generating 1000 livelihoods.
                    </p>
                    <p>
                        Working with the Government and other stakeholders, Safe Water
                        Network aims to reach millions in need of safe water through broad-scale
                        replication of best practice models. The SEWAH Center of Excellence houses
                        training toolkits, e-Modules, knowledge documents, and the current policy and
                        regulatory documents to support Safe Water Enterprises. Further, they provide
                        Technical Assistance to the state governments for operationalizing their
                        sub-optimally functioning Water stations. We are a Key Resource Center of
                        the Department of Drinking Water and Sanitation, the Ministry of Jal Shakti,
                        and the Advisory Support Unit of the Ministry of Housing and Urban Affairs.
                    </p>
                    <p>
                        Powertec, through its CSR initiatives helped fund a safe drinking water initiative in Khammam District Court, Telangana through a “Water ATM” project which provides 1000 LPH of safe drinking water. The raw water comes from surface water provided by Khammam Municipal Corporation. The technology used for treatment is 6 step filtration process uses sand filter, activated carbon filter, ultra violet light, residual chlorination followed by a series of micron filter and patented American technology Nano-ceram and provides drinking water matching the national clean drinking water standards. Further, the funds provided by Powertec were used towards a campaign to encourage behavior change to pay for safe water and not consume water from untreated sources.
                        The set of activities conducted included –
                    </p>
                    <ul>
                        <li>Awareness generation that a Water ATM has been set up in the vicinity </li>
                        <li>Focus group discussions around the station with n with key opinion leaders, schools, ASHA, and Anganwadi </li>
                        <li>
                            House to house visits by field mobilizer to distribute cards
                        </li>
                        <li>
                            Distribution of pamphlets in 3 languages Telugu, Hindi, and English with newspapers for awareness.
                        </li>
                        <li>
                            Display of posters on the Water ATMs
                        </li>
                        <li>
                            24x7 neon signage to announce the availability
                        </li>
                        <li>
                            Illustrating process for automatic dispensing using coins and RFID cards
                        </li>
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={hideSafeDialog}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
        </Layout>
    )
}


export const Head = () => (
    <Seo title='CSR' />
)